import React, { useRef } from "react";
import "./call-footer.scss";

import HandshakeIcon from "../../images/svg/handshake.inline.svg";
import PhoneIcon from "../../images/svg/phone.inline.svg";
import BriefIcon from "../../images/svg/brief.inline.svg";
import MailIcon from "../../images/svg/mail.inline.svg";
import Section from "../air-section/air-section";

interface Props {
  children?: any;
  lang?: string;
}

const CallFooter = ({ children, lang }: Props) => {
  const sectionRef = useRef();
  if (!lang) {
    lang = 'pl';
  }
  const onActionClick = () => {
    if (sectionRef.current) {
      sectionRef.current.classList.add('active');
    }
  }
  const onCloseClick = () => {
    if (sectionRef.current) {
      sectionRef.current.classList.remove('active');
    }
  }
  return (
    <Section className="call-footer container air-p" ref={sectionRef}

    >
      <button className="close-button" type="button" onClick={onCloseClick}>
        &times;
      </button>
      <div className="intro-wrapper">
        <h2
        >
          {
            lang === 'pl' ? <>
              <em>Chcesz zrealizować z nami projekt?</em>
              <br />
              <em>Skontaktuj się</em>
            </> : <>
                <em>Interested?</em>
                <br />
                <em>Reach out</em>
              </>
          }
        </h2>
        <div className="action-wrapper">
          <button type="button" className="action-button" onClick={onActionClick} >
            <HandshakeIcon />
          </button>
        </div>
      </div>

      <div className="info-wrapper r :c-4 :w :c-6:xxs :a-ca">
        <div className="phone-channel">
          <a href="phone:+48 665 035 451">
            <div className="icon-wrapper">
              <PhoneIcon style={{ fill: "white" }} />
            </div>
            <span className="title">+48 665 035 451</span>
          </a>
        </div>
        <div>
          <div className="mail-channel">
            <a href="mailto:biuro@aplinet.pl">
              <div className="icon-wrapper">
                <MailIcon style={{ fill: "white" }} />
              </div>
              <span className="title">biuro@aplinet.pl</span>

            </a>
          </div>
        </div>
        <div>
          <div className="mail-channel">
            <a href="#" onClick={() => { alert('w trakcie budowy') }}>
              <div className="icon-wrapper">
                <BriefIcon style={{ fill: "white" }} />
              </div>
              <span className="title">{lang === 'pl' ? 'Wypełnij brief' : 'Estimate project'}</span>

            </a>
          </div>
        </div>

      </div>
    </Section>
  );
};

export default CallFooter;
